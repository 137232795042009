import styled, { css } from "styled-components";
import media from "../../styles/breakPoints"

import TitleSection from "../../objects/TitleSection";

const WrapInfo = styled.div`
   width: 100%;
   height: auto;
   display: block;
   margin: 0;
   padding: 30px 16px;

   ${media.greaterThan("md")`
      width: 50%;
      border-left: 1px solid;
      border-image: linear-gradient(to top, transparent 0%, transparent 6%, #757575 6%, #757575 84%, transparent 84%, transparent 100%) 1;
   `}

   ${media.greaterThan("lg")`
      padding: 30px 32px 30px 60px;
   `}

`

const Title = styled(TitleSection)`
   font-size: ${props => props.theme.size.mediumBig};
   text-transform: uppercase;
   margin-bottom: 4rem;
   &:after {
      content: none;
   }

   ${media.greaterThan("md")`
      margin-bottom: 3.6rem;
   `}

`

const listitem = css`
   width: 100%;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.verySmall};
   font-weight: normal;
   color: ${props => props.theme.color.grayMedium};
   text-align: center;
   line-height: 2rem;
   margin: 0;
   padding: 0;

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.small};
      line-height: 2.2rem;
      text-align: left;
   `}

`

const ListContacts = styled.ul`
   margin: 0;
   padding: 0;
   list-style: none;
   &:not(:last-child) {
      margin-bottom: 2rem;
   }
`

const ContactItem = styled.li`
   ${listitem}
   width: 100%;
   &.-destaque {
      font-weight: bold;
   }
   &.-variant {
      font-size: ${props => props.theme.size.mediumSmall};
   }
`

export {
   WrapInfo,
   Title,
   ListContacts,
   ContactItem
}
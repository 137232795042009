import React, { useState } from "react"
import { navigate } from "gatsby-link"
import { useForm } from "react-hook-form"
import { useIntl } from 'gatsby-plugin-intl';

import { WrapForm,
         Title,
         InputItem,
         FieldItem,
         MsgErrorField,
         TextArea,
         TextAreaItem,
         ActionArea,
         Button } from "./styles";

function encode(data) {
   return Object
      .keys(data)
      .map((key) => 
         encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join("&")
}
   
// FORM CONTACT NETLIFY FORMS + ZAPIER + SENDGRID + REACT HOOK FORM
const ContactForm = () => {

   const intl = useIntl();

   const [formValues, setformValues] = useState({})

   const { register, handleSubmit, errors, reset } = useForm({
      mode: "onChange"
   })

   const handleChange = e => {
      setformValues({ ...formValues, [e.target.name]: e.target.value })
   }

   const onSubmit = async (data, e) => {
      e.preventDefault()
      await fetch("/", {
         method: "POST",
         headers: { "Content-Type": "application/x-www-form-urlencoded" },
         body: encode({
            "form-name": "contact",
            ...formValues,
         }),
      })
         .then(() => {
            reset()
            navigate("/success/")
         })
         .catch (error => { 
            alert(error) 
         }) 
   }

   return (
      <WrapForm 
         name="contact"
         method="post" 
         action="/success/"
         data-netlify="true"
         data-netlify-honeypot="bot-field"
         onSubmit={handleSubmit(onSubmit)}
      >
         <input type="hidden" name="form-name" value="contact" />
         <Title title={intl.formatMessage({ id: "contactpage.messagesubtitle" })} />
         <InputItem  className="name">
            <FieldItem 
               id="name" 
               name="name"
               type="text" 
               placeholder={intl.formatMessage({ id: "contactpage.placeholder1" })}
               onChange={handleChange}
               ref={register({
                  required: `* ${intl.formatMessage({ id: "contactpage.emptyfield" })}`,
                  minLength: {
                     value: 2, 
                     message: `* ${intl.formatMessage({ id: "contactpage.shortname" })}`
                  },
                  maxLength: {
                     value: 50, 
                     message: `* ${intl.formatMessage({ id: "contactpage.longname" })}`
                  }
               })}
            />
            {errors.name && 
               <MsgErrorField>{errors.name.message}</MsgErrorField>
            }
         </InputItem>
         <InputItem className="company">
            <FieldItem 
               id="company" 
               name="company"
               type="text" 
               placeholder={intl.formatMessage({ id: "contactpage.placeholder2" })}
               onChange={handleChange}
               ref={register({
                  required: `* ${intl.formatMessage({ id: "contactpage.emptyfield" })}`,
                  minLength: {
                     value: 2, 
                     message: `* ${intl.formatMessage({ id: "contactpage.shortname" })}`
                  },
                  maxLength: {
                     value: 50, 
                     message: `* ${intl.formatMessage({ id: "contactpage.longname" })}`
                  }
               })}
            />
            {errors.company && 
               <MsgErrorField>{errors.company.message}</MsgErrorField>
            }
         </InputItem> 
         <InputItem className="email">
            <FieldItem
               id="email"
               name="email" 
               type="email" 
               placeholder="E-mail"
               onChange={handleChange}
               ref={register({
                  required: `* ${intl.formatMessage({ id: "contactpage.emptyfield" })}`,
                  pattern: {
                     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                     message: `* ${intl.formatMessage({ id: "contactpage.validemail" })}`,
                  }
               })}
            />
            {errors.email && 
               <MsgErrorField>{errors.email.message}</MsgErrorField>
            }
         </InputItem>
         <InputItem className="phone">
            <FieldItem
               id="phone"
               name="phone"  
               type="text" 
               placeholder={intl.formatMessage({ id: "contactpage.placeholder3" })}
               onChange={handleChange}
               ref={register({
                  required: `* ${intl.formatMessage({ id: "contactpage.emptyfield" })}`,
                  pattern: {
                     value: /^1\d\d(\d\d)?$|^0800 ?\d{3} ?\d{4}$|^(\(0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d\) ?|0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d[ .-]?)?(9|9[ .-])?[2-9]\d{3}[ .-]?\d{4}$/gm,
                     message: `* ${intl.formatMessage({ id: "contactpage.validphone" })}`,
                  }
               })}
            />
            {errors.phone && 
               <MsgErrorField>{errors.phone.message}</MsgErrorField>
            }
         </InputItem>
         <TextAreaItem>
            <TextArea
               id="message" 
               name="message"
               type="text" 
               rows="3" 
               placeholder={intl.formatMessage({ id: "contactpage.placeholder4" })}
               onChange={handleChange}
               ref={register({
                  required: `* ${intl.formatMessage({ id: "contactpage.emptyfield" })}`,
                  minLength: {
                     value: 5, 
                     message: `* ${intl.formatMessage({ id: "contactpage.shortmsg" })}`
                  }
               })}

            />
            {errors.message && 
               <MsgErrorField>{errors.message.message}</MsgErrorField>
            }
         </TextAreaItem>
         <ActionArea className="btnsubmit">
            <Button type="submit">
               {intl.formatMessage({ id: "contactpage.buttonsubmit" })}
            </Button>
         </ActionArea>
      </WrapForm>   
   )
}

export default ContactForm
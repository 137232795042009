import React from "react";
import { useIntl } from 'gatsby-plugin-intl';

import { WrapLocal, Title, LocalMap } from "./styles";

const ContatosLocal = () => {

   const intl = useIntl();

   return (
      <WrapLocal>
         <Title title={intl.formatMessage({ id: "contactpage.localsubtitle" })} />
         <LocalMap 
            title="Mapa localização Eletrosil"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.9954280684533!2d-46.56595898482863!3d-23.568607684679378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5c2f70ba59e3%3A0x1ede2498bc0445e9!2sR.+Ten.+Chantr%C3%A9%2C+337+-+Vila+Diva+(Zona+Leste)%2C+S%C3%A3o+Paulo+-+SP%2C+03351-080!5e0!3m2!1spt-BR!2sbr!4v1561760900824!5m2!1spt-BR!2sbr" width="100%" 
            height="300" 
            frameborder="0" 
            allowfullscreen />
      </WrapLocal>
   )
};

export default ContatosLocal;
import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import { WrapInfo,
         Title,
         ListContacts,
         ContactItem } from "./styles";

const ContactInfo = () => {

   const intl = useIntl();

   const content = useStaticQuery (
      graphql`
         query {
            contato: prismicContacts { 
               data {
                  andress { text }
                  region { text }
                  city { text }
                  state { text }
                  code_postal { text }
                  pabx1 { text }
                  email_vendas { text }
                  email_tecnico { text }
                  email_qualidade { text }
                  email_financeiro { text }
                  email_compras { text }
                  email_rh { text }
               }
            }
         }
      `
   )


   return (
      <WrapInfo>
         <Title title={intl.formatMessage({ id: "contactpage.contactsubtitle" })} />
         <ListContacts>
            <ContactItem className="-destaque -variant">
               Eletrosil Indústria Metalúrgica Ltda.
            </ContactItem>
            <ContactItem>
               {content.contato.data.andress.text}&nbsp;&nbsp;—&nbsp;&nbsp;  
               {content.contato.data.region.text}<br/>
               {content.contato.data.city.text}- 
               {content.contato.data.state.text}&nbsp;&nbsp;—&nbsp;&nbsp; 
               CEP {content.contato.data.code_postal.text}  
            </ContactItem>
            <ContactItem className="-destaque">
               {intl.formatMessage({ id: "contactpage.phonelabel" })}:&nbsp;
               {content.contato.data.pabx1.text}  
            </ContactItem>
         </ListContacts>
         <ListContacts>
            <ContactItem className="-destaque">
               {intl.formatMessage({ id: "contactpage.emaillabel_1" })}:
            </ContactItem>
            <ContactItem>
               {content.contato.data.email_vendas.text}
            </ContactItem>
         </ListContacts>
         <ListContacts>
            <ContactItem className="-destaque">
               {intl.formatMessage({ id: "contactpage.emaillabel_2" })}:
            </ContactItem>
            <ContactItem>
               {content.contato.data.email_tecnico.text}
            </ContactItem>
         </ListContacts>
         <ListContacts>
            <ContactItem className="-destaque">
               {intl.formatMessage({ id: "contactpage.emaillabel_3" })}:
            </ContactItem>
            <ContactItem>
               {content.contato.data.email_qualidade.text}
            </ContactItem>
         </ListContacts>
         <ListContacts>
            <ContactItem className="-destaque">
               {intl.formatMessage({ id: "contactpage.emaillabel_4" })}:
            </ContactItem>
            <ContactItem>
               {content.contato.data.email_financeiro.text}
            </ContactItem>
         </ListContacts>
         <ListContacts>
            <ContactItem className="-destaque">
               {intl.formatMessage({ id: "contactpage.emaillabel_5" })}:
            </ContactItem>
            <ContactItem>
               {content.contato.data.email_compras.text}
            </ContactItem>
         </ListContacts>
         <ListContacts>
            <ContactItem className="-destaque">
            {intl.formatMessage({ id: "contactpage.emaillabel_6" })}:
            </ContactItem>
            <ContactItem>
               {content.contato.data.email_rh.text}
            </ContactItem>
         </ListContacts>
      </WrapInfo>
   )
}

export default ContactInfo
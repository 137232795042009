import React from "react";
import styled from "styled-components";
import media from "../styles/breakPoints"
import { useIntl } from 'gatsby-plugin-intl';

import Layout from "../components/Layout";
import SEO from "../components/seo"

import Section from "../objects/Section"
import Container from "../objects/Container"
import TitleSection from "../objects/TitleSection"

import ContactInfo from "../components/ContactInfo"
import ContactForm from "../components/ContactForm"
import ContactLocal from "../components/ContactLocal"

const ContactsSection = styled(Section)`
   padding: 50px 0;
   background-color: ${props => props.theme.color.grayLight};
`

const ContactsContainer = styled(Container)`
   display: block;

   ${media.greaterThan("md")`
      display: flex;
      align-items: flex-start;
   `}

`

const Contatos = () => {

   const intl = useIntl();

   return (
      <Layout>
         <SEO 
            lang={intl.locale}
            title={intl.formatMessage({ id: "navbar.item5" })}
            keywords={[ `indústria metalúrgica em São Paulo`, `enviar mensagem`, `contatos`, `localização` ]}
         />
         <TitleSection 
            tagAs="h1"
            className="-variant"
            title={intl.formatMessage({ id: "navbar.item5" })}
         />
         <ContactsSection>
            <ContactsContainer>
               <ContactForm />
               <ContactInfo />
            </ContactsContainer>
         </ContactsSection>
         <ContactLocal />
      </Layout>
   )
}

export default Contatos
import styled, { css } from "styled-components";
import media from "../../styles/breakPoints"

import TitleSection from "../../objects/TitleSection";

const WrapForm = styled.form`
   width: 100%;
   height: auto;
   display: block;
   margin: 0;
   padding: 30px 16px;

   ${media.greaterThan("md")`
      width: 50%;
   `}

   ${media.greaterThan("lg")`
      padding: 30px 60px 30px 32px;
   `}

`

const Title = styled(TitleSection)`
   font-size: ${props => props.theme.size.mediumBig};
   text-transform: uppercase;
   margin-bottom: 4rem;
   &:after {
      content: none;
   }
`

const InputItem = styled.div`
   position: relative;
   width: 100%;
   margin-bottom: 2.8rem;
`

const field = css`
   width: 100%;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.small};
   color: ${props => props.theme.color.grayMedium};
   background-color: ${props => props.theme.color.grayClear};
   border: none;
   outline: none;
   resize: none;
   border-radius: 5px;
   padding: 1rem 1.6rem;
`
const FieldItem = styled.input`
   ${field}
   height: 4rem;
`

const TextArea = styled.textarea`
   ${field}
   position: absolute;
   height: 100%;
   top: 0;
   left: 0;
`

const TextAreaItem = styled(InputItem)`
   width: 100%;
   height: 120px;
`

const MsgErrorField = styled.p`
   width: 100%;
   position: absolute;
   bottom: -35px;
   left: 0;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.verySmall};
   color: ${props => props.theme.color.error};
`

const ActionArea = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;

   ${media.greaterThan("sm")`
      display: block;
   `}

`
const Button = styled.button`
   display: inline-block;
   margin: 0 auto;
   background-color: ${props => props.theme.color.primaryDark};
   color: ${props => props.theme.color.grayClear};
   padding: 7px 12px;
   border-radius: 5px;
   border: none;
   text-transform: uppercase;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.small};
   text-align: center;
   font-weight: bold;
   text-decoration: none;
   cursor: pointer;
   transition: all 300ms linear;
   opacity: 0.9;
   &:hover {
      transition: all 300ms linear;
      text-decoration: inherit;
      opacity: 1;
   }

   ${media.greaterThan("sm")`
      font-size: ${props => props.theme.size.mediumSmall};
      padding: 10px 20px;
   `}

`

export {
   WrapForm,
   Title,
   InputItem,
   FieldItem,
   TextArea,
   TextAreaItem,
   MsgErrorField,
   ActionArea,
   Button
}
import styled from "styled-components";

import Section from "../../objects/Section";
import TitleSection from "../../objects/TitleSection";

const WrapLocal = styled(Section)`
   flex-direction: column;
   background-color: ${props => props.theme.color.grayLight};
`

const Title = styled(TitleSection)`
   font-size: ${props => props.theme.size.mediumBig};
   text-transform: uppercase;
   margin-bottom: 20px;
   &:after {
      content: none;
   }
`

const LocalMap = styled.iframe`
   margin: 0;
   border: none;
`

export {
   WrapLocal,
   Title,
   LocalMap
}